import styles from './Button.module.scss';
import classNames from 'classnames/bind';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import Icon, { IconTypes } from '../icon/Icon';

const cx = classNames.bind(styles);

type ButtonGroup = 'primary' | 'secondary' | 'outlined' | 'text';
export type ButtonVariant = 'primary' | 'secondary' | 'outlined' | 'text';
export type ButtonColor = 'default' | 'teal' | 'error' | 'dark';

interface IProps extends ComponentPropsWithoutRef<'button'> {
  left?: React.ReactNode;
  leftIcon?: IconTypes;
  right?: React.ReactNode;
  rightIcon?: IconTypes;
  variant?: ButtonGroup;
  size?: 'lg' | 'md' | 'sm';
  color?: ButtonColor;
  children?: React.ReactNode;
  load?: boolean;
}

const iconSize: { [size in Required<IProps>['size']]: 18 | 24 } = {
  lg: 24,
  md: 24,
  sm: 18,
};

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3606642885/Button}
 */
const Button = forwardRef<HTMLButtonElement, IProps>(function ForwardButton(props, ref) {
  const {
    left,
    right,
    leftIcon,
    rightIcon,
    color = 'default',
    variant = 'primary',
    size = 'md',
    className,
    children,
    ...htmlAttr
  } = props;

  return (
    <button type={'button'} {...htmlAttr} className={cx('button', variant, color, className, size)} ref={ref}>
      {left}
      {leftIcon && <Icon name={leftIcon} size={iconSize[size]} />}
      <span>{children}</span>
      {rightIcon && <Icon name={rightIcon} size={iconSize[size]} />}
      {right}
    </button>
  );
});

const Primary = (props: Omit<IProps, 'variant'>) => <Button {...props} variant={'primary'} />;
const Secondary = (props: Omit<IProps, 'variant'>) => <Button {...props} variant={'secondary'} />;
const Outlined = (props: Omit<IProps, 'variant'>) => <Button {...props} variant={'outlined'} />;
const Text = (props: Omit<IProps, 'variant'>) => <Button {...props} variant={'text'} />;

//FIXME : forwardRef Type 에러
(Button as any).Primary = Primary;
(Button as any).Secondary = Secondary;
(Button as any).Outlined = Outlined;
(Button as any).Text = Text;

export default Button;
